import React, { useState, useEffect } from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { graphql } from "gatsby"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { Helmet } from "react-helmet"

import {
  Header,
  HeroBanner,
  MarketingCTA,
  NewsletterDialog,
  Footer,
  CartProvider,
  ProductLine,
  Splash,
  SEO,
  ParallaxBox,
  InstagramLine,
  MobileOrientation,
  CovidBanner,
  PopUp,
} from "components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"
import marketingCtaParser from "../../../contentModels/marketingCta"
import CtaParser from "../../../utilities/marketingCtaParser"


import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"
import axios from "axios"

addLocaleData([...locale_en, ...locale_es])

//checks if the modal has been disabled by the user
const checkifModalShouldBeRendered = () => {
  if (typeof window !== "undefined") {
    return (
      !window.localStorage.getItem("didTheUserCloseTheModal") && (
        <NewsletterDialog bgImgLeft={LeafLeft} bgImgRight={LeafRight}/>
      )
    )
  }
}
const IndexPage = ({ data, pageContext, location }) => {
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  const numImages = 6
  const instaUserId = "purahw_latam"
  const [imageNodes, setNodes] = useState(null)


  useEffect(() => {
    axios
      .get(`https://www.instagram.com/${instaUserId}/?__a=1`)
      .then(userInfo => {
        setNodes(
          userInfo.data.graphql.user.edge_owner_to_timeline_media.edges.splice(
            0,
            numImages,
          ),
        )
      })
  }, [])

  let setCookie = function(name, value, days) {
    let expires = ""
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }
  let getCookie = function(name) {
    let nameEQ = name + "="
    let ca
    if (typeof document !== "undefined") {
      ca = document.cookie.split(";")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
      }
    }
    return null
  }

  const [open, setOpen] = useState(true)
  let isViewedFromCookie = getCookie("_splash_viewed")

  let removeSplash = function() {
    setCookie("_splash_viewed", true)
    setOpen(false)
  }

  const slides = data.content.edges[0].node.heroBanner.slides
  const heroBannerMediaPosition =
    data.content.edges[0].node.heroBanner.mediaPosition
  const heroBannerColor = data.content.edges[0].node.heroBanner.heroBannerColor
  const slideData = []

  for (let slide of slides) {
    let slideObject = {
      titleText: slide.richTitle,
      copyText: slide.richDescription,
      imageSrc: slide.image.fluid,
      videoSrc: slide.image.file.url,
      staticImg: slide.image.file.url,
      mediaType: slide.mediaType,
      mediaPosition: heroBannerMediaPosition,
      color: heroBannerColor,
    }
    slideData.push(slideObject)
  }

  const parallaxPosition =
    data.parallaxItem.edges && data.parallaxItem.edges[0].node
      ? parseInt(data.parallaxItem.edges[0].node.parallaxPosition)
      : 0

  const marketingCTAs = data.content.edges[0].node.marketingCtAs

  const marketingCtaData = []

  for (let mCta of marketingCTAs) {
    let mCtaObject = {
      richTitle: mCta.richTitle,
      richDescription: mCta.richDescription,
      picture: mCta.picture,
      mediaVimeoUrl: mCta.mediaVimeoUrl,
      mediaType: mCta.mediaType,
      marketingCtaType: mCta.marketingCtaType,
      orderNumber: mCta.orderNumber,
      contentPosition: mCta.contentPosition,
      buttonText: mCta.buttonText,
      redirectLink: `${langKey}/${mCta.redirectLink}`,
      buttonColor: mCta.buttonColor,
      color: mCta.backgroundColor,
      mediaPosition: mCta.mediaPosition,

      hideBackground:
        mCta.backgroundImage !== null && mCta.backgroundImage ? false : true,
    }
    marketingCtaData.push(mCtaObject)
  }
  const CtaLength = marketingCtaData.length

  let firstMarketingCTAs
  let secondMarketingCTAs

  if (parallaxPosition <= 0) {
    firstMarketingCTAs = []
    secondMarketingCTAs = marketingCtaData
  } else if (parallaxPosition > 0 && parallaxPosition <= CtaLength) {
    firstMarketingCTAs = marketingCtaData.slice(0, parallaxPosition - 1)
    secondMarketingCTAs = marketingCtaData.slice(
      parallaxPosition - 1,
      CtaLength,
    )
  } else {
    firstMarketingCTAs = marketingCtaData
    secondMarketingCTAs = []
  }

  const productLineData = {
    titleText: data.content.edges[0].node.productLine.titleText,
    items: [
      {
        id: data.content.edges[0].node.productLine.productOne.id,
        title:
        data.content.edges[0].node.productLine.productOne.productLineTitle,
        imageSrc: data.content.edges[0].node.productLine.productOne.image.fixed,
        buttonText:
        data.content.edges[0].node.productLine.productOne.cardButtonText,
        color: data.content.edges[0].node.productLine.productOne.color,
        slug: data.content.edges[0].node.productLine.productOne.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productTwo.id,
        title:
        data.content.edges[0].node.productLine.productTwo.productLineTitle,
        imageSrc: data.content.edges[0].node.productLine.productTwo.image.fixed,
        buttonText:
        data.content.edges[0].node.productLine.productTwo.cardButtonText,
        color: data.content.edges[0].node.productLine.productTwo.color,
        slug: data.content.edges[0].node.productLine.productTwo.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productThree.id,
        title:
        data.content.edges[0].node.productLine.productThree.productLineTitle,
        imageSrc:
        data.content.edges[0].node.productLine.productThree.image.fixed,
        buttonText:
        data.content.edges[0].node.productLine.productThree.cardButtonText,
        color: data.content.edges[0].node.productLine.productThree.color,
        slug: data.content.edges[0].node.productLine.productThree.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
    ],
  }

  const instagramData = {
    // nodes: data.instagram.nodes,
  }

  const parallaxItem = data.parallaxItem.edges
    ? data.parallaxItem.edges[0].node
    : null

  const splashData = {
    mediaSrc: data.splash.edges[0].node.media.file.url,
    mediaType: data.splash.edges[0].node.mediaType,
    titleText: data.splash.edges[0].node.richTitle,
    copyText: data.splash.edges[0].node.richDescription,
    buttonText: data.splash.edges[0].node.buttonText,
    buttonColor: data.splash.edges[0].node.buttonColor,
    mediaPosition: data.splash.edges[0].node.mediaPosition,
    pageContext: pageContext,
    logo: data.splash.edges[0].node.logo.file.url,
  }

  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)

  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  const pageMetadata = {
    title: data.content.edges[0].node.metadataTitle,
    description:
    data.content.edges[0].node.metadataDescription.internal.content,
    url: location.href,
  }

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <MobileOrientation/>
      <SEO siteMetadata={pageMetadata}/>
      <Helmet>
        <meta
          name="google-site-verification"
          content={process.env.google_site_verification_code}
        />
      </Helmet>
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline/>
          <ThemeProvider theme={Theme}>
            {open && !isViewedFromCookie && (
              <Splash removeSplash={removeSplash} {...splashData} />
            )}
            {isViewedFromCookie && (
              <CartProvider cartId={undefined}>
                {checkifModalShouldBeRendered()}

                <PopUp/>

                <Header
                  data={data.header.edges[0].node}
                  slug={pageContext.slug}
                />
                <CovidBanner/>
                <HeroBanner isProductLine={false} slideData={slideData}/>
                {firstMarketingCTAs.length !== 0 && (
                  <CtaParser data={firstMarketingCTAs}/>
                )}
                <ParallaxBox item={parallaxItem}/>
                <ProductLine productLineData={productLineData}/>
                {secondMarketingCTAs.length !== 0 && (
                  <CtaParser data={secondMarketingCTAs}/>
                )}
                {imageNodes && <InstagramLine feed={imageNodes}/>}
                <Footer
                  data={data.footer.edges[0].node}
                  langKey={langKey}
                  bgImgLeft={LeafLeft}
                  bgImgRight={LeafRight}
                />
              </CartProvider>
            )}
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($locale: String!) {
    content: allContentfulLandingPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          heroBanner {
            mediaPosition
            heroBannerColor
            slides {
              mediaType
              image {
                file {
                  url
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              richDescription {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              richTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
            }
          }
          marketingCtAs {
            backgroundColor
            backgroundImage
            richDescription {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            richTitle {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            marketingCtaType
            orderNumber
            mediaPosition
            contentPosition
            buttonColor
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            redirectLink
            mediaType
            mediaCaption
            mediaVimeoUrl
            picture {
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          productLine {
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            node_locale
            productOne {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productTwo {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productThree {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
          }
          metadataTitle
          metadataDescription {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }

    splash: allContentfulSplashPage(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          richDescription {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          richTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          buttonColor
          buttonText {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          mediaType
          media {
            file {
              url
            }
          }
        }
      }
    }
    parallaxItem: allContentfulParallaxItem(
      limit: 1
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          richDescription {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          richTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          parallaxPosition
          titleText
          backgroundImage {
            file {
              url
            }
          }
          ctaLink
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
